<template>
    <div>
        <v-breadcrumbs :items="crumbs" divider="/" style="background-color: #f0f0f0">
        <template v-slot:item="{ item }">
            <v-breadcrumbs-item
                href="#"
                :disabled="item.disabled"
            >
                {{ item.text }}
            </v-breadcrumbs-item>
        </template>
        </v-breadcrumbs>
        <v-card class="mx-auto" outlined tile color="#F8F8FF">
            <v-tabs v-model="tab">
                <v-tab
                    v-for="item in tab_items"
                    :key="item.tab"
                >
                    {{ item.tab }}
                </v-tab>
                <v-spacer></v-spacer>
                <div class="pa-2">
                    <v-btn @click.prevent="goBack" small text color="blue" height="100%"><v-icon small left>fa fa-arrow-left</v-icon> Back</v-btn>
                </div>
            </v-tabs>

            <v-tabs-items v-model="tab">
                <v-tab-item>
                    <v-card flat>
                        <v-card-title>Profile Details</v-card-title>
                        <v-form ref="ct" action="#" @submit.prevent="beforeCreateForm('update')" lazy-validation>
                            <v-container class="fill-height"
                            fluid>
                                <v-row>
                                    <v-col cols="12" md="12">
                                        <v-text-field
                                            dense
                                            outlined
                                            v-model="form.ID"
                                            label="ID"
                                            required
                                            disabled
                                            hide-details
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="12">
                                        <v-text-field
                                            dense
                                            outlined
                                            v-model="form.adminID"
                                            label="Admin ID"
                                            required
                                            disabled
                                            hide-details
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="12">
                                        <v-text-field
                                            dense
                                            outlined
                                            v-model="form.userID"
                                            label="User ID"
                                            required
                                            disabled
                                            hide-details
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="12">
                                        <v-text-field
                                            dense
                                            outlined
                                            v-model="form.name.name"
                                            :rules="form.name.rule"
                                            label="Name"
                                            required
                                            :disabled="readonly"
                                            hide-details="auto"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="12">
                                        <v-text-field
                                            dense
                                            outlined
                                            v-model="form.email"
                                            label="Email"
                                            required
                                            disabled
                                            hide-details
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="12">
                                        <v-text-field
                                            dense
                                            outlined
                                            v-model="form.profileid"
                                            label="Profile ID"
                                            required
                                            disabled
                                            hide-details
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="12">
                                        <v-autocomplete
                                        dense
                                        outlined
                                        placeholder="Select Type"
                                        label="Type"
                                        v-model="type.selected"
                                        :items="type.items"
                                        item-text="name"
                                        item-value="ID"
                                        :disabled="readonly"
                                        :rules="type.rule"
                                        :loading="type.loader"
                                        hide-details="auto"
                                        >
                                        </v-autocomplete>
                                    </v-col>
                                    <v-col cols="12" md="12">
                                        <v-autocomplete
                                        dense
                                        outlined
                                        placeholder="Select Role"
                                        label="Role"
                                        v-model="role.selected"
                                        :items="role.items"
                                        item-text="name"
                                        item-value="ID"
                                        :disabled="readonly"
                                        :rules="role.rule"
                                        :loading="role.loader"
                                        hide-details="auto"
                                        >
                                        </v-autocomplete>
                                    </v-col>
                                    <v-col cols="12" md="12">
                                        <v-text-field
                                            dense
                                            outlined
                                            v-model="form.createddate"
                                            label="Created Date"
                                            required
                                            disabled
                                            hide-details
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="12">
                                        <v-text-field
                                            dense
                                            outlined
                                            v-model="form.updateddate"
                                            label="Updated Date"
                                            required
                                            disabled
                                            hide-details
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="12">
                                        <v-text-field
                                            dense
                                            outlined
                                            v-model="form.lastlogindate"
                                            label="Last Login Date"
                                            required
                                            disabled
                                            hide-details
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="12">
                                        <span class="text-body-2">Status</span>
                                        <v-switch
                                        color="success"
                                        class="col-1 pl-0"
                                        v-model="form.status"
                                        :label="`${form.status ? 'Enabled' : 'Disabled'}`"
                                        :disabled="readonly"
                                        hide-details
                                        ></v-switch>
                                    </v-col>
                                    <v-card-actions>
                                        <v-btn v-if="validateAccess('user_updateprofile')" class="mt-4" tile small color="primary" type="submit" :loading="btn_loading"><v-icon left x-small>fa fa-save</v-icon> Save</v-btn>
                                        <v-btn class="mt-4" tile small color="error" type="button" @click="goBack"><v-icon left x-small>fa fa-ban</v-icon> Cancel</v-btn>
                                        <v-btn v-if="validateAccess('user_removeprofile')" @click="viewDelete(items, 'delete')" class="mt-4" tile small color="error" type="button"><v-icon left x-small>fa fa-trash</v-icon> Remove Profile</v-btn>
                                        <v-btn v-if="validateAccess('user_resetpassword')" @click="viewMore('reset')" class="mt-4" tile small outlined color="error" type="button"><v-icon left x-small>fa fa-redo-alt</v-icon> Reset Password</v-btn>
                                    </v-card-actions>
                                </v-row>
                            </v-container>
                        </v-form>
                    </v-card>
                </v-tab-item>
                <v-tab-item>
                    <v-card flat>
                        <v-card-title>Access</v-card-title>
                        <v-form ref="ct" action="#" lazy-validation>
                            <v-container class="fill-height"
                            fluid>
                                <v-row>
                                    <v-col cols="12" md="6"><v-btn :loading="btn_loading" :disabled="btn_loading" tile small color="primary" @click="viewMore('create')"><v-icon left x-small dark>fa fa-cogs</v-icon>Create Access
                                        <template v-slot:loader>
                                            <span>Loading...</span>
                                        </template>
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="12" md="6">
                                        <v-text-field placeholder="Search Car Park" outlined class="search-textfield" hide-details dense v-model="search"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="12">
                                        <v-data-table
                                            calculate-widths
                                            :headers="headers"
                                            :items.sync="socitems"
                                            hide-default-footer
                                            :loading="tableloading"
                                            :search="search"
                                            loading-text= "Loading Data... Please wait"
                                            :items-per-page="100"
                                            id="main-table"
                                            dense
                                        >
                                            <template slot="item" slot-scope="props">
                                                <tr>
                                                    <td>{{page > 1 ? (page - 1) * 100 + props.index + 1 : props.index + 1}}</td>
                                                    <td>{{props.item.ProfileAdminID}}</td>
                                                    <td>
                                                        <v-tooltip top v-if="true">
                                                            <template v-slot:activator="{on}">
                                                                <a v-on="on" @click="viewUpdateSOC(props.item)" style="text-decoration: underline" id="custom-hover">{{props.item.ProfileID}}</a>
                                                            </template>
                                                            <span>Update</span>
                                                        </v-tooltip>
                                                        <span v-else>{{props.item.ProfileID}}</span>
                                                    </td>
                                                    <td>
                                                        <ul>
                                                        <li v-for="(itm, idx) in props.item.SystemName" :key="idx">{{itm}}</li>
                                                        </ul>
                                                    </td>
                                                    <td>
                                                        <ul>
                                                        <li v-for="(itm, idx) in props.item.OperatorName" :key="idx">{{itm}}</li>
                                                        </ul>
                                                    </td>
                                                    <td>
                                                        <ul>
                                                        <li v-for="(itm, idx) in props.item.CarParkName" :key="idx">{{itm}}</li>
                                                        </ul>
                                                    </td>
                                                    <td>{{props.item.createDate}}</td>
                                                    <td>{{props.item.updateDate}}</td>
                                                    <td>
                                                        <v-tooltip top>
                                                        <template v-slot:activator="{ on }">
                                                        <span>
                                                            <a v-on="on" id="custom-hover" @click.prevent="viewDeleteSOC(props.item, 'delete')"><v-icon color="red" right x-small>fa fa-trash</v-icon></a>
                                                        </span>
                                                        </template>
                                                        <span>Remove</span>
                                                        </v-tooltip>
                                                    </td>
                                                </tr>
                                            </template>
                                        </v-data-table>
                                    </v-col>
                                    <v-card-actions>
                                        <v-btn class="mt-4" tile small color="primary" type="submit" :loading="btn_loading"><v-icon left x-small>fa fa-save</v-icon> Save</v-btn>
                                        <v-btn class="mt-4" tile small color="error" type="button" @click="goBack"><v-icon left x-small>fa fa-ban</v-icon> Cancel</v-btn>
                                    </v-card-actions>
                                </v-row>
                            </v-container>
                        </v-form>
                    </v-card>
                </v-tab-item>
            </v-tabs-items>
            <v-overlay
            :absolute="absolute"
            :value="overlay"
            >
                <v-progress-circular
                indeterminate
                color="primary"
                ></v-progress-circular>
            </v-overlay>
        </v-card>
        <v-snackbar
            v-model="swal.notification"
            top
            :color="swal.scolor"
            >
            {{ swal.message }}
        </v-snackbar>
        <create :dialog="ma.modal" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" :allInfo="ma.allInfo" @confirm="onConfirm" @closed="onClosed"></create>
        <update :dialog="ma.modal_update" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" :allInfo="ma.allInfo" @confirm="onConfirm" @closed="onClosed"></update>
        <alertbox :dialog="ma.alertmodal" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" @confirm="onConfirm" @closed="onClosed"></alertbox>
        <alertdelete :dialog="ma.modal_delete" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" :item="ma.item" :allInfo="ma.allInfo" @confirm="onConfirm" @closed="onClosed"></alertdelete>
        <alertbox :dialog="ma.modal_reset" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" @confirm="onConfirm" @closed="onClosed"></alertbox>
    </div>
</template>

<script>
import timer from '../../assets/js/sleeper.js';
import {ERR} from '../../assets/js/errhandle.js';
import create from '.././modals/CreateSOC2';
import update from '.././modals/UpdateSOC';
import edit from '.././modals/EditRate';
import alertbox from '../modals/Alert';
import alertdelete from '../modals/AlertDeleteProfile';
import TextEditor from '../TextEditor';
import * as moment from "moment";
import * as tz from "moment-timezone";
import * as AppStorage from '../../assets/js/storage';
import {rule} from '../../assets/js/checkPerm';

export default {
    name: 'User Profile Details',
    title: 'User Profile Details',
    
    data()  {
        return {
            crumbs: [
                {
                text: 'Home',
                disabled: true,
                href: '#',
                },

                {
                text: 'Administration',
                disabled: true,
                href: '#',
                },

                {
                text: 'User Management',
                disabled: true,
                href: '#',
                },

                {
                text: 'Internal Users',
                disabled: true,
                href: '#',
                },

                {
                text: 'Profile',
                disabled: true,
                href: '#',
                },
            ],
            system: {
                items: [],
                itemsFull: [],
                selected: '',
                loader: false,
                disable: false,
                rule: []
            },
            operator: {
                items: [],
                itemsFull: [],
                selected: '',
                loader: false,
                disable: false,
                rule: []
            },
            type: {
                items: [],
                itemsFull: [],
                selected: '',
                loader: false,
                disable: false,
                rule: []
            },
            role: {
                items: [],
                itemsFull: [],
                selected: '',
                loader: false,
                disable: false,
                rule: []
            },
            carpark: {
                header: [
                    { text: '#', value: 'row', width: '5%' },
                    { text: 'Name', value: 'name', width: '90%' },
                ],
                items: [],
                itemsFull: [],
                selected: [],
                loader: false,
                disable: false,
                rule: []
            },
            items: {},
            socitems: [],
            search: '',
            tableloading: false,
            headers: [
                { text: '#', value: 'row', width: '3%' },
                { text: 'Admin ID', value: 'ProfileAdminID', width: '5%' },
                { text: 'Profile ID', value: 'ProfileID', width: '5%' },
                { text: 'System', value: 'SystemName', width: '5%' },
                { text: 'Operator', value: 'OperatorName', width: '10%' },
                { text: 'Car Park', value: 'CarParkName', width: '10%' },
                { text: 'Created Date', value: 'createDate', width: '5%', sort: function (a, b) {
                    let aa = moment(a, 'DD-MM-YYYY hh:mm:ss A');
                    let aa2 = aa.toDate();

                    let bb = moment(b, 'DD-MM-YYYY hh:mm:ss A');
                    let bb2 = bb.toDate();

                    return aa2 > bb2 ? -1 : aa2 < bb2 ? 1 : 0;
                }  },
                { text: 'Updated Date', value: 'updateDate', width: '5%', sort: function (a, b) {
                    let aa = moment(a, 'DD-MM-YYYY hh:mm:ss A');
                    let aa2 = aa.toDate();

                    let bb = moment(b, 'DD-MM-YYYY hh:mm:ss A');
                    let bb2 = bb.toDate();

                    return aa2 > bb2 ? -1 : aa2 < bb2 ? 1 : 0;
                }  },
                { text: 'Action', value: 'action', width: '10%' },
            ],
            pages: 0,
            page: 1,
            swal: {
                notification: false,
                message: '',
                scolor: '',
            },
            ma: {
                modal: false,
                modal_action_type: '',
                modalInfo: [],
                customText: [],
                confirmCreate: false,

                alertmodal: false,

                modal_update: false,
                modal_delete: false,
                modal_reset: false
            },
            form: {
                profileid: '',
                ID: '',
                adminID: '',
                userID: '',
                name: {name: '', rule: []},
                email: '',
                status: false,
                createddate: '',
                updateddate: '',
                lastlogindate: '',
            },
            componentloader: false,
            role_selected: [],
            perm_list: [],
            select_all: false,
            btn_loading: false,
            access_record: [],
            overlay: false,
            absolute: true,
            loading: false,
            tab: null,
            tab_items: [
            { tab: 'Profile Details', content: 'Tab 1 Content' },
            { tab: 'Access', content: 'Tab 2 Content' },
            ],

            start: 0,
            timeout: null,
            rowHeight: 24,
            perPage: 25,
            deletetype: '',
            readonly: false
        }
    },

    components: {create, edit, alertbox, 'text-editor': TextEditor, alertdelete, update},

    async created(){
        this.$vuetify.goTo(0);
        if (!this.validateAccess('user_updateprofile')){
            this.readonly = true;
        }
        await this.getAllSystem();
        await this.getOperatorFull();
        await this.getProfileType();
        await this.getProfileRole();
        await this.getCarParkFull();
        await this.initVal();

        await this.getAdminSOC();
    },

    async beforeRouteLeave (to, from , next) {
        AppStorage.remove("usrdetails");
        next();
    },

    methods: {
        async getAdminSOC(){
            this.tableloading = true;
            let response = await this.$store.dispatch('getAdminsSOC', {})
            if (response.data.code == 'AP000'){
                let dt = response.data.record;

                for(var i=0; i<dt.length; i++){
                    if(this.items.ProfileID == dt[i].ProfileID){
                        let systemname = [];
                        let removebracketfromsystemid = dt[i].SystemID.slice(1,-1);
                        let systemidnumber = removebracketfromsystemid.split(',').map(it => Number(it));
                        console.log('systemidnumber', systemidnumber);
                        let sysid = this.system.itemsFull.filter(itm => systemidnumber.includes(itm.SystemID));
                        console.log('sysid', sysid);
                        systemname = sysid.map(it => it.SystemName);

                        let operatorname = [];
                        let operatorid = '';
                        if (dt[i].OperatorID != "[4ALLOP]"){
                            operatorid = dt[i].OperatorID.slice(1,-1);
                            let operatoridnumber = operatorid.split(',').map(it => Number(it));
                            let opid = this.operator.itemsFull.filter(itm => operatoridnumber.includes(itm.OperatorID));
                            operatorname = opid.map(it => it.OperatorName);
                        }

                        else{
                            operatorname = ["All Operators"];
                            operatorid = "4ALLOP";
                        }

                        let carparkname = [];
                        let carparkid = '';
                        if (dt[i].CarparkID != "[4ALLCP]"){
                            carparkid = dt[i].CarparkID.slice(1,-1);
                            let cpidnumber = carparkid.split(',').map(it => Number(it));
                            let cpid = this.carpark.itemsFull.filter(itm => cpidnumber.includes(itm.CarParkID));
                            carparkname = cpid.map(it => it.CarParkName);
                        }

                        else{
                            carparkname = ["All Car Parks"];
                            carparkid = "4ALLCP";
                        }

                        dt[i].row = this.page > 1 ? (this.page - 1) * 100 + i + 1 : i + 1;
                        dt[i].createDate = moment(new Date(dt[i].createDate)).format('DD-MM-YYYY hh:mm:ss A');
                        dt[i].updateDate = dt[i].updateDate == null ? "N/A" : moment(new Date(dt[i].updateDate)).format('DD-MM-YYYY hh:mm:ss A');
                        dt[i].OperatorName = operatorname;
                        //dt[i].OperatorID = operatorid;
                        dt[i].CarParkName = carparkname;
                        dt[i].SystemName = systemname;
                    }
                }

                let filtered = dt.filter(item => item.ProfileID == this.items.ProfileID)

                this.socitems = filtered;
                this.tableloading = false;
            }
        },

        async getAllSystem(){
            let list = [];
            try{
                let response = await this.$store.dispatch("getAllSystem2", {search: ''});
                let dt = response.data.record;
                if (response.data.code == "AP000"){
                    for(var i=0; i<dt.length; i++){
                        dt[i].name = `${dt[i].SystemID} - ${dt[i].SystemName}`;

                        this.system.items.push(dt[i]);
                        this.system.itemsFull.push(dt[i]);
                    }
                }

                list = dt;
            }

            catch(err){
                console.log(err);
            }

            return list;
        },

        async getOperatorFull(){
            let list = [];
            this.operator.items = [];
            this.operator.itemsFull = [];
            this.operator.selected = "";

            try{
                this.operator.loader = true;
                this.operator.disable = true;

                let self = this;
                let response = await this.$store.dispatch("listOperator", {search: ''});
                let dt = response.data.record;
                if (response.data.code == 'AP000'){
                    this.operator.items.push({name: "All Operator", OperatorID: ""});
                    this.operator.itemsFull.push({name: "All Operator", OperatorID: ""});

                for(var i=0; i<dt.length; i++){
                    //if (dt[i].SystemID === 2){
                        dt[i].name = `${dt[i].OperatorID} - ${dt[i].OperatorName}`;
                        this.operator.items.push({name: dt[i].name, OperatorID: dt[i].OperatorID});
                        this.operator.itemsFull.push(dt[i]);
                    //}
                }
                }

                this.operator.loader = false;
                this.operator.disable = false;
            }

            catch(err){
                console.log(err);
            }

            return list;
        },

        async getCarParkFull(){
            let list = [];
            this.carpark.items = [];
            this.carpark.itemsFull = [];
            this.tableloading = true;

            try{
                this.carpark.loader = true;
                this.carpark.disable = true;

                let self = this;

                let response = await this.$store.dispatch("getAllCarpark2", {});
                    let dt = response.data.record;
                    if (response.data.code == 'AP000'){
                        for(var i=0; i<dt.length; i++){
                            dt[i].row = this.page > 1 ? (this.page - 1) * 100 + i + 1 : i + 1;
                            dt[i].name = `${dt[i].CarParkID} - ${dt[i].CarParkName}`;
                            this.carpark.items.push({row: dt[i].row, name: dt[i].name, CarParkID: dt[i].CarParkID});
                            this.carpark.itemsFull.push(dt[i]);
                        }
                    }

                //this.operator.itemsFull = dt;

                this.carpark.loader = false;
                this.carpark.disable = false;
                this.tableloading = false;
            }

            catch(err){
                console.log(err);
            }

            return list;
        },

        async getProfileType(){
            let list = [];
            this.type.items = [];
            this.type.selected = "";

            try{
                this.type.loader = true;
                this.type.disable = true;

                let self = this;
                let response = await this.$store.dispatch("listProfileType", {});
                let dt = response.data.record;
                if (response.data.code == 'AP000'){
                    this.type.items.push({name: "Select Type", ID: ""});
                    this.type.itemsFull.push({name: "Select Type", ID: ""});

                    for(var i=0; i<dt.length; i++){
                        dt[i].name = `${dt[i].ID} - ${dt[i].TypeName}`;
                        this.type.items.push({name: dt[i].name, ID: dt[i].ID});
                        this.type.itemsFull.push(dt[i]);
                    }
                }

                this.type.loader = false;
                this.type.disable = false;
            }

            catch(err){
                console.log(err);
            }

            return list;
        },

        async getProfileRole(){
            let list = [];
            this.role.items = [];
            this.role.selected = "";

            try{
                this.role.loader = true;
                this.role.disable = true;

                let self = this;
                let response = await this.$store.dispatch("listProfileRole", {});
                let dt = response.data.record;
                if (response.data.code == 'AP000'){
                    this.role.items.push({name: "Select Role", ID: ""});
                    this.role.itemsFull.push({name: "Select Role", ID: ""});

                    for(var i=0; i<dt.length; i++){
                        dt[i].name = `${dt[i].ID} - ${dt[i].RoleName}`;
                        this.role.items.push({name: dt[i].name, ID: dt[i].ID});
                        this.role.itemsFull.push(dt[i]);
                    }
                }

                this.role.loader = false;
                this.role.disable = false;
            }

            catch(err){
                console.log(err);
            }

            return list;
        },

        async createSOC(v){
            try{
                let system = `[${v[0].selected}]`;
                this.tableloading = true;
                let req = {profileid: this.items.ProfileID, userid: this.items.userID, systemid: system, operatorid: v.operator, carparkid: v.carpark};
                let response = await this.$store.dispatch("createSOC", req)
                
                if (response.data.code === 'AP000'){
                    this.swal.notification = true;
                    this.swal.scolor = 'green';
                    this.swal.message = `${ERR.HANDLE(response.data.code)}: Access Created!`;
                    await this.getAdminSOC();
                }

                else{
                    this.swal.notification = true;
                    this.swal.scolor = 'red';
                    this.swal.message = `${ERR.HANDLE(response.data.code)}`;
                }

                this.tableloading = false;
            }

            catch(err){
                console.log(err);
                this.swal.scolor = 'red';
                this.swal.notification = true;
                this.swal.message = "Something went wrong. Please try again later";
                this.tableloading = false;
            }
        },

        async updateSOC(v){
            try{
                let system = `[${v[0].selected}]`;
                this.tableloading = true;
                let req = {profileadminid: this.ma.modalInfo.ProfileAdminID, profileid: this.items.ProfileID, userid: this.items.userID, systemid: system, operatorid: v.operator, carparkid: v.carpark};
                let response = await this.$store.dispatch("updateSOC", req)
                
                if (response.data.code === 'AP000'){
                    this.swal.notification = true;
                    this.swal.scolor = 'green';
                    this.swal.message = `${ERR.HANDLE(response.data.code)}: Access Updated!`;
                    await this.getAdminSOC();
                }

                else{
                    this.swal.notification = true;
                    this.swal.scolor = 'red';
                    this.swal.message = `${ERR.HANDLE(response.data.code)}`;
                }

                this.tableloading = false;
            }

            catch(err){
                console.log(err);
                this.swal.scolor = 'red';
                this.swal.notification = true;
                this.swal.message = "Something went wrong. Please try again later";
                this.tableloading = false;
            }
        },

        async updateProfile(){
            try{
                this.tableloading = true;
                let response;
                let response2;
                if (this.items.ProfileID == null || this.items.ProfileID == "N/A"){
                    let req = {userid: this.items.userID, type: this.type.selected, role: this.role.selected};
                    response = await this.$store.dispatch("createProfile", req)

                    if (response.data.code === 'AP000'){
                        this.$router.push({name: 'Internal Users', query: {action: 'createProfile'}});
                    }

                    else{
                        this.tableloading = false;
                        this.swal.notification = true;
                        this.swal.scolor = 'red';
                        this.swal.message = `Error`;
                    }
                }

                else{
                    let req = {profileid: this.items.ProfileID, userid: this.items.userID, type: this.type.selected, role: this.role.selected};
                    response = await this.$store.dispatch("updateProfile", req)

                    let req2 = {adminid: this.items.adminID, name: this.form.name.name, activestatus: this.form.status};
                    response2 = await this.$store.dispatch("userUpdateAccount", req2)

                    if (response.data.code === 'AP000' && response2.data.code === 'AP000'){
                        this.$router.push({name: 'Internal Users', query: {action: 'createProfile'}});
                    }

                    else{
                        this.tableloading = false;
                        this.swal.notification = true;
                        this.swal.scolor = 'red';
                        this.swal.message = `Error`;
                    }
                }
            }

            catch(err){
                console.log(err);
                this.swal.scolor = 'red';
                this.swal.notification = true;
                this.swal.message = "Something went wrong. Please try again later";
                this.tableloading = false;
            }
        },

        async deleteProfile(){
            try{
                this.tableloading = true;
                let req = {profileid: this.items.ProfileID};
                let response = await this.$store.dispatch("deleteProfile", req)
                
                if (response.data.code === 'AP000'){
                    this.$router.push({name: 'Internal Users', query: {action: 'profileremoval'}});
                }

                else{
                    this.tableloading = false;
                    this.swal.notification = true;
                    this.swal.scolor = 'red';
                    this.swal.message = `${ERR.HANDLE(response.data.code)}`;
                }
            }

            catch(err){
                console.log(err);
                this.swal.scolor = 'red';
                this.swal.notification = true;
                this.swal.message = "Something went wrong. Please try again later";
                this.tableloading = false;
            }
        },

        async deleteSOC(v){
            try{
                this.tableloading = true;
                let req = {profileadminid: this.ma.modalInfo.ProfileAdminID};
                let response = await this.$store.dispatch("deleteSOC", req)
                if (response.data.code === 'AP000'){
                        await this.getAdminSOC();
                        this.swal.notification = true;
                        this.swal.scolor = 'green';
                        this.swal.message = `${ERR.HANDLE(response.data.code)}: SOC has been deleted!`;
                }

                else{
                    this.loading = false;
                    this.swal.notification = true;
                    this.swal.scolor = 'red';
                    this.swal.message = `${ERR.HANDLE(response.data.code)}`;
                }
            }

            catch(err){
                this.swal.scolor = 'red';
                this.swal.notification = true;
                this.swal.message = "Something went wrong. Please try again later";
                this.tableloading = false;
            }
        },

        async userReset(){
            try{
                this.tableloading = true;
                let response = await this.$store.dispatch("userReset", {email: this.form.email})
                if (response.data.code === 'AP000'){
                        this.swal.notification = true;
                        this.swal.scolor = 'green';
                        this.swal.message = `${ERR.HANDLE(response.data.code)}: Password has been reset!`;
                }

                else{
                    this.tableloading = false;
                    this.swal.notification = true;
                    this.swal.scolor = 'red';
                    this.swal.message = `${ERR.HANDLE(response.data.code)}`;
                }
            }

            catch(err){
                this.swal.scolor = 'red';
                this.swal.notification = true;
                this.swal.message = "Something went wrong. Please try again later";
                this.tableloading = false;
            }
        },

        validateAccess(val){
            let access = JSON.parse(JSON.stringify(this.$store.getters.getAccess))
            return rule.checkaccess(val, access);
        },

        goBack(){
            this.$router.push({name: 'Internal Users'});
        },

        async initVal(){
            if (this.$route.query.row !== undefined) AppStorage.set("usrdetails", this.$route.query.row);
            this.$router.replace({'query': null});
            this.items = this.$route.query.row || AppStorage.get('usrdetails');
            this.items = JSON.parse(this.items);
            this.form.profileid = this.items.ProfileID;
            this.form.ID = this.items.ID;
            this.form.adminID = this.items.adminID;
            this.form.userID = this.items.userID;
            this.form.name.name = this.items.name;
            this.operator.selected = this.items.OperatorID;
            this.form.createddate = this.items.createDate;
            this.form.updateddate = this.items.updateDate;
            this.form.lastlogindate = this.items.lastLoginDate;
            this.form.email = this.items.email;
            this.form.status = this.items.ActiveStatus;
            this.role.selected = this.items.Role == undefined ? "" : this.items.Role;
            this.type.selected = this.items.Type == undefined ? "" : this.items.Type;
            await this.getCarParkFull(); //reload carpark list with filtered based on operator id
        },

        async beforeCreateForm(flag){
            this.form.name.rule = [
                v => !!v || "Name is required"
            ];

            this.type.rule = [
                v => !!v || 'Type is required'
            ];

            this.role.rule = [
                v => !!v || 'Role is required'
            ];

            let self = this;

            setTimeout(function () {
             if (self.$refs.ct.validate()) {
                self.ma.alertmodal = true;
                //self.ma.modalInfo = {id: 1, name: 2};
                self.ma.modal_action_type = flag;
                    //self.addRateCard();
                }
            });
        },
        
        viewMore(flag){
            if (flag == 'create'){
                this.ma.modal = true;
                this.ma.allInfo = this.items;
            }

            if (flag == 'reset'){
                this.ma.modal_reset = true;
            }

            this.ma.customText = {text1: "Gate ID", text2: "Name"};
            this.ma.modal_action_type = flag;
        },

        viewUpdateSOC(row){
            this.ma.modal_update = true;
            this.ma.modalInfo = row;

            this.ma.modal_action_type = 'update';
        },

        viewDelete(row, flag){
            if (flag == 'delete'){
                this.ma.modal_delete = true;
                this.ma.modalInfo = row;
            }

            this.ma.modal_action_type = 'delete';
            this.deletetype = 'deleteprofile'
        },

        viewDeleteSOC(row, flag){
            if (flag == 'delete'){
                this.ma.modal_delete = true;
                this.ma.modalInfo = row;
            }

            this.ma.modal_action_type = 'delete';
            this.deletetype = 'deletesoc'
        },

        onClosed(value){
            if (value == false){
                this.ma.modal = value;
                this.ma.alertmodal = value;
                this.ma.modal_delete = value;
                this.ma.modal_reset = value;
                this.ma.modal_update = value;
            }
        },

        onConfirm(value){
            if (value == true){
                if (this.ma.modal_action_type == 'update')
                {
                    this.ma.alertmodal = false;
                    this.updateProfile();
                }
                //this.addValuePass(formatteddays);

                if (this.ma.modal_action_type == 'delete' && this.deletetype == 'deleteprofile')
                {
                    this.ma.modal_delete = false;
                    this.deleteProfile();
                }

                if (this.ma.modal_action_type == 'delete' && this.deletetype == 'deletesoc')
                {
                    this.ma.modal_delete = false;
                    this.deleteSOC();
                }

                if (this.ma.modal_action_type == 'reset')
                {
                    this.ma.modal_reset = false;
                    this.userReset();
                }
            }

            if (value.flag == 'create'){
                this.createSOC(value);
                this.ma.modal = false;
            }

            if (value.flag == 'update'){
                this.updateSOC(value);
                this.ma.modal_update = false;
            }
        },
    }
}
</script>