<template>
<v-row justify="center">
<v-dialog v-model="dialog_local" max-width="650">
    <v-form ref="ct" action="#" @submit.prevent="beforeCreateForm('update')" lazy-validation>
        <v-card class="mx-auto" tile>
        <v-card-title class="justify-space-between" style="background-color: #3D348B; color: white; padding-top: 5px; padding-bottom: 5px">
            Update SOC
            <v-btn text @click="$emit('closed', false)" fab depressed small><v-icon small color="white">fas fa-times</v-icon></v-btn>
            </v-card-title>
        <v-card-text>
        <v-container class="fill-height"
        fluid >
            <v-row>
                <v-col cols="12" md="12">
                    <!--
                    <v-autocomplete
                        dense
                        v-model="form[0].selected"
                        :items="form[0].system"
                        :rules="form[0].rule"
                        @change="execFilter('system')"
                        item-text="name"
                        item-value="SystemID"
                        label="Select System"
                        outlined
                        hide-details="auto"
                    ></v-autocomplete>
                    -->
                    <span class="text-body-2">Select System</span>
                    <v-checkbox @change="execFilter('system')" multiple dense style="flex: 0 0 50%; max-width: 50%;" v-for="(item, index) of form[0].system" hide-details :value="item.SystemID" v-model="form[0].selected" :key="index">
                        <template v-slot:label class="checkbox-role"><span style="width: 100%" @click="changeOrder">{{item.SystemID}} - {{item.SystemName}}</span></template>
                    </v-checkbox>
                    <v-input
                        error
                        readonly
                        v-model="form[0].selected"
                        :rules="form[0].rule"
                        height="0"
                        class="hide-input-text mt-2"
                    >
                    </v-input>
                </v-col>
                <!--
                <v-col cols="12" md="12">
                    <v-autocomplete
                        dense
                        v-model="form[1].selected"
                        :items="form[1].operator"
                        :rules="form[1].rule"
                        @change="execFilter('operator')"
                        item-text="name"
                        item-value="OperatorID"
                        label="Select Operator"
                        outlined
                        hide-details="auto"
                    ></v-autocomplete>
                </v-col>
                -->
                <v-col cols="12" md="12">
                    <span class="text-body-2">Choose Operator Access to give access to</span>
                    <v-checkbox dense hide-details @change="selectAll_operator" class="col-5 pl-0" v-model="select_all_operator">
                        <template v-slot:label class="checkbox-role"><span style="width: 100%">All Operator</span></template>
                    </v-checkbox>
                </v-col>
                <v-col cols="12" md="12">
                    <span class="text-body-2">Or select specifically from below</span>
                </v-col>
                <v-col cols="12" offset-md="6" md="6">
                    <v-text-field placeholder="Search Operator" outlined class="search-textfield" hide-details dense v-model="search_operator"></v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                    <v-data-table
                        :headers="form[1].header"
                        v-model="form[1].selected"
                        :items="form[1].operator"
                        show-select
                        item-key="OperatorID"
                        hide-default-footer
                        :search="search_operator"
                        loading-text= "Loading Data... Please wait"
                        :items-per-page="100"
                        dense
                        @toggle-select-all="removeSelectAll_operator"
                        @input="execFilter('operator')"
                        disable-pagination
                        v-scroll:#virtual-scroll-table-op="onScroll_operator"
                        id="virtual-scroll-table-op"
                    >
                    <template
                        v-if="start_operator > 0"
                        v-slot:body.prepend
                    >
                        <tr>
                        <td
                            :colspan="form[1].header.length"
                            :style="'padding-top:'+startHeight_operator+'px'"
                        >
                        </td>
                        </tr>
                    </template>
                    <template
                        v-if="start_operator + perPage_operator < form[1].operator.length"
                        v-slot:body.append
                    >
                        <tr>
                        <td
                            :colspan="form[1].operator.length"
                            :style="'padding-top:'+endHeight_operator+'px'"
                        >
                        </td>
                        </tr>
                    </template>
                    </v-data-table>
                    <v-input
                        error
                        readonly
                        v-model="form[1].selected"
                        :rules="form[1].rule"
                        height="0"
                        class="hide-input-text mt-2"
                    >
                    </v-input>
                </v-col>
                <v-col cols="12" md="12">
                    <span class="text-body-2">Choose Car Park Access to give access to</span>
                    <v-checkbox dense hide-details @change="selectAll" class="col-5 pl-0" v-model="select_all">
                        <template v-slot:label class="checkbox-role"><span style="width: 100%">All Car Park</span></template>
                    </v-checkbox>
                </v-col>
                <v-col cols="12" md="12">
                    <span class="text-body-2">Or select specifically from below</span>
                </v-col>
                <v-col cols="12" offset-md="6" md="6">
                    <v-text-field placeholder="Search Car Park" outlined class="search-textfield" hide-details dense v-model="search"></v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                    <v-data-table
                        :headers="form[2].header"
                        v-model="form[2].selected"
                        :items="form[2].carpark"
                        show-select
                        item-key="CarParkID"
                        hide-default-footer
                        :search="search"
                        loading-text= "Loading Data... Please wait"
                        :items-per-page="100"
                        dense
                        @toggle-select-all="removeSelectAll"
                        disable-pagination
                        v-scroll:#virtual-scroll-table-cp="onScroll"
                        id="virtual-scroll-table-cp"
                    >
                    <template
                        v-if="start > 0"
                        v-slot:body.prepend
                    >
                        <tr>
                        <td
                            :colspan="form[2].header.length"
                            :style="'padding-top:'+startHeight+'px'"
                        >
                        </td>
                        </tr>
                    </template>
                    <template
                        v-if="start + perPage < form[2].carpark.length"
                        v-slot:body.append
                    >
                        <tr>
                        <td
                            :colspan="form[2].carpark.length"
                            :style="'padding-top:'+endHeight+'px'"
                        >
                        </td>
                        </tr>
                    </template>
                    </v-data-table>
                    <v-input
                        error
                        readonly
                        v-model="form[2].selected"
                        :rules="form[2].rule"
                        height="0"
                        class="hide-input-text mt-2"
                    >
                    </v-input>
                </v-col>
            </v-row>
        </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
            <v-btn tile type="submit" small color="primary"><v-icon left x-small dark>fa fa-save</v-icon>Save</v-btn>
            <v-btn tile color="blue darken-1" text @click="$emit('closed', false)">Close</v-btn>
        </v-card-actions>
        </v-card>
    </v-form>
    <alertbox :dialog="innerModal" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" @confirm="onConfirm" @closed="onClosed"></alertbox>
</v-dialog>
</v-row>
</template>
<script>
import alertbox from './Alert';
export default {
    props: ['dialog', 'modalInfo', 'customText', 'system', 'master', 'allInfo'],
    
    data()  {
        return {
            dialog_local: false,
            innerModal: false,
            form: [ 
                {system: [], itemsFull: [], rule: [], selected: [] },
                {header: [{text: 'ID', value: 'OperatorID'}, {text: 'Operator', value: "name"}], operator: [], itemsFull: [], rule: [], selected: [] },
                {header: [{text: 'ID', value: 'CarParkID'}, {text: 'Car Park', value: "name"}], carpark: [], itemsFull: [], rule: [], selected: [], temp: [] }
            ],

            ma: {
                modal: false,
                modal_action_type: '',
                modalInfo: [],
                customText: [],
                edit_modal: false,
                view_modal: false,
                confirmEdit: false,
                confirmCreate: false,
            },

            search: '',
            search_operator: '',
            select_all_operator: false,
            select_all: false,
            start: 0,
            timeout: null,
            rowHeight: 10,
            perPage: 10,

            start_operator: 0,
            timeout_operator: null,
            rowHeight_operator: 10,
            perPage_operator: 10,
        }
    },

    components: {alertbox},

    watch: {
        dialog: {
        handler: async function(value) {
            this.modalHandler();
            if(value == true){
                await this.handleValue();
            }
        },
        },

        dialog_local: {
        handler: function(value) {
            this.modalCloseHandler();
        },
        },
    },

    created(){
        this.$watch(() => this.form[0].system, this.onRuleChange);
        this.$watch(() => this.form[1].operator, this.onRuleChange);
        this.$watch(() => this.form[1].selected, function(value) {
            if (value.length > 0){
                this.select_all_operator = false;
            }
        });
        this.$watch(() => this.form[2].carpark, this.onRuleChange);
        this.$watch(() => this.form[2].selected, function(value) {
            if (value.length > 0){
                this.select_all = false;
            }
        });
    },

    computed: {
        itemsLimited() {
        return this.form[2].carpark.slice(this.start, this.perPage+this.start);
        },
        startHeight() {
        return this.start * this.rowHeight - 32;
        },
        endHeight() {
        return this.rowHeight * (this.form[2].carpark.length - this.start);
        },

        itemsLimited_operator() {
        return this.form[1].operator.slice(this.start_operator, this.perPage_operator+this.start_operator);
        },
        startHeight_operator() {
        return this.start_operator * this.rowHeight_operator - 32;
        },
        endHeight_operator() {
        return this.rowHeight_operator * (this.form[1].operator.length - this.start_operator);
        },
    },

    methods: {
        async getAllSystem(){
            let list = [];
            try{
                let response = await this.$store.dispatch("getAllSystem2", {search: ''});
                let dt = response.data.record;
                //this.form[0].system.push({name: "Select System", SystemID: ""});
                //this.form[0].itemsFull.push({name: "Select System", SystemID: ""});
                if (response.data.code == "AP000"){
                    for(var i=0; i<dt.length; i++){
                        dt[i].name = `${dt[i].SystemID} - ${dt[i].SystemName}`;

                        this.form[0].system.push(dt[i]);
                        this.form[0].itemsFull.push(dt[i]);
                    }
                }

                list = dt;
            }

            catch(err){
                console.log(err);
            }

            return list;
        },

        async getAllOperator(){
            let list = [];
            try{
                let response = await this.$store.dispatch("getAllOperator", {search: ''});
                let dt = response.data.record;
                //this.form[1].operator.push({name: "Select Operator", OperatorID: ""});
                //this.form[1].itemsFull.push({name: "Select Operator", OperatorID: ""});

                //this.form[1].operator.push({name: "All Operator", OperatorID: "4ALLOP"});
                //this.form[1].itemsFull.push({name: "All Operator", OperatorID: "4ALLOP"});
                if (response.data.code == "AP000"){
                    for(var i=0; i<dt.length; i++){
                        dt[i].name = `${dt[i].OperatorID} - ${dt[i].OperatorName}`;

                        //this.form[1].operator.push(dt[i]);
                        this.form[1].itemsFull.push(dt[i]);
                    }
                }

                list = dt;
            }

            catch(err){
                console.log(err);
            }

            return list;
        },

        async getAllCarpark(){
            let list = [];
            try{
                let response = await this.$store.dispatch("getAllCarpark2", {});
                let dt = response.data.record;
                //this.form[2].carpark.push({name: "Select Car Park", CarParkID: ""});
                //this.form[2].itemsFull.push({name: "Select Car Park", CarParkID: ""});
                if (response.data.code == "AP000"){
                    for(var i=0; i<dt.length; i++){
                        dt[i].name =  `${dt[i].CarParkID} - ${dt[i].CarParkName}`;

                        //this.form[2].carpark.push(dt[i]);
                        this.form[2].itemsFull.push(dt[i]);
                    }
                }

                list = dt;
            }

            catch(err){
                console.log(err);
            }

            return list;
        },

        onRuleChange(after, before) {
            this.form[0].rule = [];
            this.form[1].rule = [];
            this.form[2].rule = [];
        },

        async beforeCreateForm(flag){         
            await this.onRuleChange();

            this.form[0].rule = [
                v => !v.length == 0 || 'Field is required',
            ];

            if (!this.select_all_operator){
                this.form[1].rule = [
                    v => !v.length == 0 || 'Field is required',
                ];
            }

            if (!this.select_all){
                this.form[2].rule = [
                    v => !v.length == 0 || 'Field is required',
                ];
            }

            //must set this in order to use 'this' as 'self' inside settimeout etc..
            let self = this;

            setTimeout(function () {
             if (self.$refs.ct.validate()) {
                    self.innerModal = true;
                    self.ma.modalInfo = {id: 1, name: 2};
                    self.ma.modal_action_type = flag;
                }
            });
        },

        modalHandler(){
            this.dialog_local = !this.dialog_local;
        },

        modalCloseHandler(){
            if (this.dialog_local == false){
                this.$emit('closed', false);
            }
        },

        async handleValue(){
            this.form[2].temp = [];
            this.select_all_operator = false;
            this.select_all = false;
            this.form[0].selected = [];
            this.form[1].selected = [];
            this.form[2].selected = [];
            this.form[0].system = [];
            this.form[0].itemsFull = [];
            this.form[1].operator = [];
            this.form[1].itemsFull = [];
            this.form[2].carpark = [];
            this.form[2].itemsFull = [];
            await this.getAllSystem();
            await this.getAllOperator();
            await this.getAllCarpark();

            //system
            let systemid = this.modalInfo.SystemID.slice(1, -1).split(',');
            console.log('systemidsystemid', systemid);
            let sysid = this.form[0].itemsFull.filter(itm => systemid.map(Number).includes(itm.SystemID));
            console.log('sysidsysid', sysid);
            this.form[0].selected = sysid.map(i => i.SystemID);

            this.execFilter('system');

            //operator
            let operatorid = this.modalInfo.OperatorID.slice(1, -1).split(',');
            if (operatorid.includes("4ALLOP")){
                this.select_all_operator = true;
            }

            else{
                let opid = this.form[1].itemsFull.filter(itm => operatorid.map(Number).includes(itm.OperatorID));
                this.form[1].selected = opid;
                console.log('systemid', operatorid.map(Number));
            }

            //carpark
            let carparkid = this.modalInfo.CarparkID.slice(1, -1).split(',');
            if (carparkid.includes("4ALLCP")){
                this.select_all = true;
            }

            else{
                let cpid = this.form[2].itemsFull.filter(itm => carparkid.map(Number).includes(itm.CarParkID));
                this.form[2].selected = cpid;
            }
        },

        async execFilter(type){
            if (type == 'system'){
                if (true){
                    let self = this;
                    if (self.form[0].selected.length > 0){
                        /*
                        let operator = this.form[1].itemsFull.filter(item => {
                        return item.SystemID == self.form[0].selected;
                        });
                        */
                        let selectedsys = this.form[0].selected.map(item => item);
                        console.log('selectedsys', selectedsys);
                        let operator = this.form[1].itemsFull.filter(itm => selectedsys.includes(itm.SystemID));

                        this.form[1].operator = [];
                        //this.form[1].selected = [];
                        
                        //this.form[1].operator.push({name: "Select Operator", OperatorID: ""});
                        //this.form[1].operator.push({name: "All Operator", OperatorID: "4ALLOP"});
                        for(var i=0; i<operator.length; i++){
                            operator[i].name = `${operator[i].OperatorID} - ${operator[i].OperatorName}`;
                            this.form[1].operator.push({name: operator[i].name, OperatorID: operator[i].OperatorID});
                        }

                        //carpark
                        let carpark = this.form[2].itemsFull.filter(itm => selectedsys.includes(itm.SystemID));

                        this.form[2].carpark = [];
                        //this.form[2].selected = [];

                        //this.form[2].carpark.push({name: "Select Car Park", CarParkID: ""});
                        for(var i=0; i<carpark.length; i++){
                            //carpark[i].name = `${carpark[i].CarParkID} - ${carpark[i].CarParkName}`;
                            //this.carpark.items.push(carpark[i]);

                            carpark[i].name =  `${carpark[i].CarParkID} - ${carpark[i].CarParkName}`;

                            this.form[2].carpark.push({name: carpark[i].name, CarParkID: carpark[i].CarParkID});
                        }
                    }

                    else{
                        this.form[1].operator = [];
                        //this.form[1].selected = [];
                        this.form[1].operator = [];

                        this.form[2].carpark = [];
                        //this.form[2].selected = [];
                        this.form[2].carpark = [];
                    }
                }
            }

            if (type == 'operator'){
                let self = this;
                if (self.form[1].selected.length > 0){
                    //let carpark = this.form[2].itemsFull.filter(item => {
                      //  return item.OperatorID == self.form[1].selected;
                    //});

                    let selectedop = this.form[1].selected.map(item => item.OperatorID);
                    console.log('selectedop', selectedop);
                    let carpark = this.form[2].itemsFull.filter(itm => selectedop.includes(itm.OperatorID));

                    this.form[2].carpark = [];
                    //this.form[2].selected = [];

                    //this.form[2].carpark.push({name: "Select Car Park", CarParkID: ""});
                    for(var i=0; i<carpark.length; i++){
                        //carpark[i].name = `${carpark[i].CarParkID} - ${carpark[i].CarParkName}`;
                        //this.carpark.items.push(carpark[i]);

                        carpark[i].name =  `${carpark[i].CarParkID} - ${carpark[i].CarParkName}`;

                        this.form[2].carpark.push({name: carpark[i].name, CarParkID: carpark[i].CarParkID});
                    }

                    //if (this.form[2].temp.length > 0){
                        //this.form[2].selected = this.form[2].temp;
                    //}
                }

                if (self.form[0].selected.length == 0 && self.form[1].selected.length == 0){
                    this.form[2].carpark = [];
                    //this.form[2].selected = [];

                    if (this.select_all_operator){
                        let selectedop = this.form[1].itemsFull.map(item => item.OperatorID);
                        let carpark = this.form[2].itemsFull.filter(itm => selectedop.includes(itm.OperatorID));

                        for(var i=0; i<carpark.length; i++){
                            carpark[i].name =  `${carpark[i].CarParkID} - ${carpark[i].CarParkName}`;
                            this.form[2].carpark.push({name: carpark[i].name, CarParkID: carpark[i].CarParkID});
                        }
                    }
                }
            }

            let op = JSON.parse(JSON.stringify(this.form[1].operator)).map(itm => itm.OperatorID);
            let removeselectedoperatornotshown = JSON.parse(JSON.stringify(this.form[1].selected)).filter(itm => op.includes(itm.OperatorID));
            this.form[1].selected = removeselectedoperatornotshown;

            let cp = JSON.parse(JSON.stringify(this.form[2].carpark)).map(itm => itm.CarParkID);
            let removeselectedcarparknotshown = JSON.parse(JSON.stringify(this.form[2].selected)).filter(itm => cp.includes(itm.CarParkID));
            console.log('removeselectedcarparknotshown', removeselectedcarparknotshown);
            this.form[2].selected = removeselectedcarparknotshown;
        },

        //set the action to be sent to watcher to execute the create/edit function
        onConfirm(value){
          //console.log(value);
                if (this.ma.modal_action_type == 'update'){
                    this.form.flag = 'update';

                    /*
                    if (this.form[1].selected == '4ALLOP'){
                        this.form[1].selected = `4ALLOP`;
                    }

                    else
                    this.form[1].selected = `[${this.form[1].selected}]`;
                    */

                    if (this.select_all_operator){
                        this.form.operator = '[4ALLOP]';
                    }

                    else{
                        let operator = this.form[1].selected.map(item => item.OperatorID);
                        this.form.operator = `[${operator.join()}]`;
                    }

                    if (this.select_all){
                        this.form.carpark = '[4ALLCP]';
                    }

                    else{
                        let carpark = this.form[2].selected.map(item => item.CarParkID);
                        this.form.carpark = `[${carpark.join()}]`;
                    }
                    
                    this.$emit('confirm', this.form);
                }

                this.innerModal = false;
        },

        onClosed(value){
            if (value == false){
                this.innerModal = value;
            }
        },

        onScroll(e) {
            // debounce if scrolling fast
            this.timeout && clearTimeout(this.timeout);
        
            this.timeout = setTimeout(() => {
                const { scrollTop } = e.target;
                const rows = Math.ceil(scrollTop / this.rowHeight);

                this.start = rows + this.perPage > this.form[2].carpark.length ?
                this.form[2].carpark.length - this.perPage: rows;

                this.$nextTick(() => {
                e.target.scrollTop = scrollTop;
                });
            }, 20);
        },

        onScroll_operator(e) {
            // debounce if scrolling fast
            this.timeout && clearTimeout(this.timeout);
        
            this.timeout = setTimeout(() => {
                const { scrollTop } = e.target;
                const rows = Math.ceil(scrollTop / this.rowHeight);

                this.start_operator = rows + this.perPage_operator > this.form[1].operator.length ?
                this.form[1].operator.length - this.perPage_operator: rows;

                this.$nextTick(() => {
                e.target.scrollTop = scrollTop;
                });
            }, 20);
        },

        selectAll(){
            if (this.select_all){
                this.form[2].selected = [];
            }
        },

        selectAll_operator(){
            if (this.select_all_operator){
                this.form[1].selected = [];
                this.execFilter('operator');
            }
        },

        removeSelectAll(){
            this.select_all = false;
        },

        removeSelectAll_operator(){
            this.select_all_operator = false;
        },

        changeOrder(e) {
            e.preventDefault();
            e.stopPropagation();
        },
    }
}
</script>